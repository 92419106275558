export const COLORS = [
  "#B12318",
  "#EB3323",
  "#F6C143",
  "#FFFE55",
  "#A0CD63",
  "#4FAD5B",
  "#4CAFEA",
  "#2D70BA",
  "#68389B",
];

export const MORE_COLORS = [
  "#FFFFFF",
  "#000000",
  "#3B74EC",
  "#45A2EF",
  "#529867",
  "#CD4A3F",
  "#EA8D40",
  "#EEC543",
  "#8E45D0",
  "#F2F2F2",
  "#7F7F7F",
  "#CBDCFC",
  "#E8F6FE",
  "#EDFAF2",
  "#FCEAE9",
  "#FDF3EC",
  "#FEF9E5",
  "#FAECFE",
  "#EEEEEE",
  "#595959",
  "#CEEBFD",
  "#DDE9FE",
  "#CBE9D7",
  "#F7CBC9",
  "#FADDC7",
  "#FDEEB5",
  "#EBCAFC",
  "#BFBFBF",
  "#3F3F3F",
  "#A0BEFA",
  "#A7DCFC",
  "#A6D5B8",
  "#F2A19C",
  "#F5BC8C",
  "#FBE281",
  "#CB94F9",
  "#A5A5A5",
  "#262626",
  "#2452B2",
  "#3473A1",
  "#417A53",
  "#922B22",
  "#AD642A",
  "#9E8329",
  "#57297D",
  "#939393",
  "#0D0D0D",
  "#15316A",
  "#1C415A",
  "#284D34",
  "#511712",
  "#573213",
  "#635217",
  "#36194E",
];
