// List of languages referenced from https://github.com/wooorm/lowlight#data
export const CODE_BLOCK_LANGUAGUE_SYNTAX_DEFAULT = "plaintext";

export const CODE_BLOCK_LANGUAGUES = [
  { syntax: "bash", alias: "sh", label: "Bash" },
  { syntax: "c", alias: "h", label: "C" },
  { syntax: "csharp", alias: "cs, c#, dotnet", label: "C#" },
  { syntax: "css", label: "CSS" },
  { syntax: "go", alias: "golang", label: "Go" },
  { syntax: "graphql", alias: "gql", label: "GraphQL" },
  { syntax: "java", alias: "jsp", label: "Java" },
  { syntax: "javascript", alias: "js, jsx, mjs, cjs", label: "Javascript" },
  { syntax: "json", label: "JSON" },
  { syntax: "kotlin", alias: "kt, kts", label: "Kotlin" },
  { syntax: "less", label: "Less" },
  { syntax: "makefile", alias: "mk, mak, make", label: "Makefile" },
  { syntax: "markdown", alias: "md, mkdown, mkd", label: "Markdown" },
  {
    syntax: "objectivec",
    alias: "mm, objc, obj-c, obj-c++, objective-c++",
    label: "Objective-C",
  },
  { syntax: "php", label: "PHP" },
  { syntax: "plaintext", alias: "text, txt", label: "Text" },
  { syntax: "python", alias: "py, gyp, ipython", label: "Python" },
  { syntax: "scss", label: "SCSS" },
  { syntax: "shell", alias: "console, shellsession", label: "Shell" },
  { syntax: "sql", label: "SQL" },
  { syntax: "typescript", alias: "ts, tsx", label: "TypeScript" },
  { syntax: "vbnet", alias: "vb", label: "VB .NET" },
  {
    syntax: "xml",
    alias: "html, xhtml, rss, atom, xjb, xsd, xsl, plist, wsf, svg",
    label: "HTML, XML",
  },
];
