export const title = "";

const content = ``;

export const mock = {
  title,
  content,
  wordCount: 483,
  cover: "https://res.cloudinary.com/dmhzdv5kf/image/upload/v1733364957/shk91N6yUj_zkms92.jpg",
  author: "Gerador de texto",
  createdAt: new Date().toLocaleDateString("pt-BR", {
    day: "numeric",
    month: "short",
    year: "numeric",
  }),
};
